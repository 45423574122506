import axios from '../http' // 导入http中创建的axios实例
import Qs from 'qs' // 根据需求是否导入qs模块
import { resquest } from './base'
// 企业列表
// logo\版权信息等等
export function getccconfig(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/getccconfig`, data2)
}
export function com_list(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/scjg/com_list`, data2);
}
// 视频列表
export function com_cam_list(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/scjg/com_cam_list`, data2);
}
// 视频url
export function com_cam_list_url(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/scjg/com_cam_list_url`, data2);
}
// 统计企业数量
export function com_count(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/scjg/com_count`, data2)
}
// 统计摄像头数量
export function cam_count(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/scjg/cam_count`, data2)
}
// 县市统计（摄像头数量、统计相关县市）
export function comcity_cam(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/scjg/comcity_cam`, data2)
}
// 企业详情
export function com_info(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/scjg/com_info`, data2)
}

// 企业相关许可证
export function com_info_list_xkz(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/scjg/com_info_list_xkz`, data2)
}
// 企业从业人员相关健康证
export function com_info_list_jkz(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/scjg/com_info_list_jkz`, data2)
}
// ++++++++++新闻相关接口++++++++++
// 新闻列表接口
export function djarticlelist(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/djarticlelist`, data2)
}
// 新闻列表接口
export function djarticlelist_type(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/djarticlelist_type`, data2)
}
// 新闻详情接口
export function djarticleinfo(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/djarticleinfo`, data2)
}
// 新闻栏目接口
export function djmenulist(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/djmenulist`, data2)
}
// ++++++++++个人中心相关接口++++++++++
// 个人中心企业数量相关接口
export function comcity_count(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/dp/scdp_city/comcity_count`, data2)
}
// ++++++++++个人中心通知等相关接口++++++++++
// 个人中心通知列表
export function article_list(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/user/scuser_com_article/article_list`, data2)
}
// 个人中心通知详情
export function article_info(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/user/scuser_com_article/article_info`, data2)
}
// 个人中心通知详情-回复列表
export function article_info_list_comment(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/user/scuser_com_article/article_info_list_comment`, data2)
}
// 个人中心通知详情-我要评论
export function article_info_comment_add(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/user/scuser_com_article/article_info_comment_add`, data2)
}

// ++++++++++个人中心企业数量统计等相关接口++++++++++
//个人中心企业故障列表
export function Fcom_list(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/user/scuser_man_online/com_list`, data2)
}
//个人中心企业数量
export function Mcom_count(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/user/scuser_man_online/com_count`, data2)
}
//个人中心企业统计（在线，不在线）
export function com_list_count(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/user/scuser_man_online/com_list_count`, data2)
}

// +++++++++企业收录相关接口++++++++++

//企业是否收录
export function com_info_me(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/scjg/com_info_me`, data2)
}
//企业收录/取消
export function com_info_me_sl(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/scjg/com_info_me_sl`, data2)
}
//收录企业列表
export function com_list_me(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/user/scuser_man_online/com_list_me`, data2)
}

export function educourse_sign_phone(data) {
    var data2 = Qs.stringify(data);
    return axios.post(`${resquest}/api/dp/dj/educourse_sign_phone?vid=` + data, data2);
}

export function getccconfig_json(v) {
    var data2 = Qs.stringify({ info: v });
    return axios.post(`${resquest}/api/pub/getccconfig_json`, data2);
}

export function com_info_list_xkz_all(guid) {
    var data2 = Qs.stringify({ cguid: guid });
    return axios.post(`${resquest}/api/pub/scjg/com_info_list_xkz_all`, data2);
}

export function com_fl_count(guid) {
    var data2 = Qs.stringify({ cguid: guid });
    return axios.post(`${resquest}/api/pub/scjg/com_fl_count`, data2);
}

export function com_fl_other(guid) {
    var data2 = Qs.stringify({ cguid: guid });
    return axios.post(`${resquest}/api/pub/scjg/com_fl_other`, data2);
}


export function mypub(subcontrol, fun, data) {
    var data2 = Qs.stringify(data);
    if (fun != '') {
        return axios.post(`${resquest}/api/pub/${subcontrol}/${fun}`, data2);
    } else {

        return axios.post(`${resquest}/api/pub/${subcontrol}`, data2);
    }
}