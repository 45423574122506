import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/',
    name: 'layout',
    meta: {
      title: '名厨亮灶',
      show: true 
    },
    component: () => import('@/views/layout/xindex.vue'),
  },
  {
    path: '/Maps',
    name: 'Maps',
    meta: {
      title: '地图',
      show: true 
    },
    component: () => import('@/views/foodView/Map.vue')
  },
  {
    path: '/information',
    name: 'information',
    meta: {
      title: '新闻资讯',
      show: true 
    },
    component: () => import('@/views/foodView/information.vue')
  },
  {
    path: '/infohelp/:id',
    name: 'infohelp',
    meta: {
      title: '帮助说明',
      show: true 
    },
    component: () => import('@/views/foodView/infohelp_list.vue')
  },
  {
    path: '/infohelp_show/:id',
    name: 'infohelp_show',
    meta: {
      title: '帮助说明',
      show: true 
    },
    component: () => import('@/views/foodView/infohelp_list_show.vue')
  },
  {
    path: '/informationMore/:id',
    name: 'informationMore',
    meta: {
      title: '资讯详情',
      show: false 
    },
    component: () => import('@/views/foodView/informationMore.vue')
  },
  //视频二级详情页
  {
    path: '/xdetails/:id',
    name: 'xdetails',
    meta: {
      title: '名厨亮灶',
      show: false 
    },
    component: () => import('@/views/foodView/xdetails.vue')
  },
  {
    path: '/video',
    name: 'video',
    meta: {
      title: '监控视频'
    },
    component: () => import('@/views/foodView/video.vue')
  },
  {
    path: '/comment/:id',
    name: 'comment',
    meta: {
      title: '添加评论',
      show: false 
    },
    component: () => import('@/components/comment/index.vue')
  },
  {
    path: '/comment_list/:id',
    name: 'comment_list',
    meta: {
      title: '评论列表'
    },
    component: () => import('@/components/comment/comment_list.vue')
  },
  //废弃部分  路由
  {
    path: '/schoolFood',
    name: 'schoolFood',
    meta: {
      title: '学校餐饮'
    },
    component: () => import('@/views/foodView/FQ_schoolFood.vue')
  },
  {
    path: '/socialFood',
    name: 'socialFood',
    meta: {
      title: '社会餐饮'
    },
    component: () => import('@/views/foodView/FQ_socialFood.vue')
  },
  {
    path: '/vidoList/:id',
    name: 'vidoList',
    meta: {
      title: '监控列表'
    },
    component: () => import('@/views/foodView/FQ_vidoList.vue')
  },
  //废弃部分  路由结束
  {
    path: '/personalInfo',
    name: 'personalInfo',
    meta: {
      title: '个人中心',
      show: true,
      auth: true,
    },
    component: () => import('@/views/personalInfo/index.vue')
  },
  {
    path: '/list_pic',
    name: 'list_pic',
    meta: {
      title: '个人中心',
      show: true,
      auth: true,
    },
    component: () => import('@/views/personalInfo/PersonalCenter2/m_list_pic.vue')
  },
  {
    path: '/list_vote',
    name: 'list_vote',
    meta: {
      title: '个人中心',
      show: true,
      auth: true,
    },
    component: () => import('@/views/personalInfo/PersonalCenter2/m_list_uvote.vue')
  },
  {
    path: '/list_vote_show',
    name: 'list_vote_show',
    meta: {
      title: '个人中心',
      show: true,
      auth: true,
    },
    component: () => import('@/views/personalInfo/PersonalCenter2/m_list_uvote_show.vue')
  },

  
  {
    path: '/list_man_vote',
    name: 'list_man_vote',
    meta: {
      title: '个人中心',
      show: true,
      auth: true,
    },
    component: () => import('@/views/personalInfo/PersonalCenter1/m_list_man_uvote.vue')
  },
  {
    path: '/list_man_vote_show',
    name: 'list_man_vote_show',
    meta: {
      title: '个人中心',
      show: true,
      auth: true,
    },
    component: () => import('@/views/personalInfo/PersonalCenter1/m_list_man_uvote_show.vue')
  },
  
  //src\views/personalInfo/PersonalCenter1/setupcenter.vue
  {
    path: '/setupcenter',
    name: 'setupcenter',
    meta: {
      title: '个人设置',
      show: false 
    },
    component: () => import('@/views/personalInfo/PersonalCenter1/setupcenter.vue')
  },
  {
    path: '/M_Password',
    name: 'M_Password',
    meta: {
      title: '修改密码',
      show: false 
    },
    component: () => import('@/views/personalInfo/PersonalCenter1/M_Password.vue')
  },
  {
    path: '/setupcenter2',
    name: 'setupcenter2',
    meta: {
      title: '个人设置',
      show: false 
    },
    component: () => import('@/views/personalInfo/PersonalCenter2/setupcenter.vue')
  },
  {
    path: '/xueshi',
    name: 'xueshi',
    meta: {
      title: '学习',
      show: false 
    },
    component: () => import('@/views/personalInfo/PersonalCenter2/xueshi.vue')
  },
  {
    path: '/M_Password2',
    name: 'M_Password2',
    meta: {
      title: '修改密码',
      show: false 
    },
    component: () => import('@/views/personalInfo/PersonalCenter2/M_Password.vue')
  },

  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      show: false 
    },
    component: () => import('@/views/login/index.vue')
  },
  //通知相关路由
  {
    path: '/J_notice',
    name: 'J_notice',
    meta: {
      title: '通知',
      show: false 
    },
    component: () => import('@/views/J_notice/index.vue')
  },
  {
    path: '/J_notice_view/:id',
    name: 'J_notice_view',
    meta: {
      title: '通知详情',
      show: false 
    },
    component: () => import('@/views/J_notice/view.vue')
  },
  //不在线故障列表src/views/J_fault/index.vue
  {
    path: '/J_fault',
    name: 'J_fault',
    meta: {
      title: '故障列表',
      show: false 
    },
    component: () => import('@/views/J_fault/index.vue')
  },
  {
    path: '/J_fault1',
    name: 'J_fault1',
    meta: {
      title: '不在线企业',
      show: false 
    },
    component: () => import('@/views/J_fault/index1.vue')
  },
  {
    path: '/J_fault2',
    name: 'J_fault2',
    meta: {
      title: '在线企业',
      show: false 
    },
    component: () => import('@/views/J_fault/index2.vue')
  },
  {
    path: '/J_fault3',
    name: 'J_fault3',
    meta: {
      title: '企业数',
      show: false 
    },
    component: () => import('@/views/J_fault/index3.vue')
  },
  //企业收录列表src/views/J_Included/index.vue
  {
    path: '/J_Included',
    name: 'J_Included',
    meta: {
      title: '企业收藏',
      show: false 
    },
    component: () => import('@/views/J_Included/index.vue')
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
//路由设置
router.beforeEach((to, form, next) => {
  const {
    title,
    auth
  } = to.meta;
  //标题
  document.title = title || process.env.BASE_URL || "名厨亮灶";
  const Token = localStorage.getItem('dptoken');
  //路由检测是否登录，登录验证
  if (auth) {
    if (!Token) {
      next({
        path:'/login'
      })
    }
  }
  next();
});

export default router
