import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import router from './router'
import store from './store'
import 'vant/lib/index.css'
import vant from 'vant'
import '@/assets/font_css/iconfont.css'
import '@/assets/font_css/iconfont.js'

import 'videojs-flash'
import 'videojs-contrib-hls'
import VideoPlayer from 'vue-video-player/src'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import VuewechatTitle from 'vue-wechat-title'



const createapp = createApp(App)
createapp.use(vant)
createapp.use(store)
createapp.use(router)
createapp.use(VideoPlayer)
createapp.use(VuewechatTitle)


createapp.mount('#app')
createapp.directive('title', (el, binding) => {//单个修改标题
    document.title = el.dataset.title
})
