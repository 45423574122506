/****   http.js   ****/
// 导入封装好的axios实例
import request from 'axios'
let Interface=request.create({
    timeout: 1000 * 60 * 10
});

Interface.interceptors.request.use(
    config=>{
        // config.headers['Content-Type'] = "application/x-www-form-urlencoded"
        config.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
        let tokens = localStorage.getItem('dptoken')
        if(tokens){
            config.headers['token'] = tokens 
        }
        return config
    },
    error=>{
        console.log(error)
    }
)

const http ={
    /**
     * methods: 请求
     * @param url 请求地址 
     * @param params 请求参数
     */
    get(url,params){
        const config = {
            method: 'get',
            url:url
        }
        if(params) config.params = params
        return Interface(config)
    },
    post(url,params){
        const config = {
            method: 'post',
            url:url
        }
        if(params) config.data = params
        return Interface(config)
    },
    put(url,params){
        const config = {
            method: 'put',
            url:url
        }
        if(params) config.params = params
        return Interface(config)
    },
    delete(url,params){
        const config = {
            method: 'delete',
            url:url
        }
        if(params) config.params = params
        return Interface(config)
    }
}
//导出
export default http