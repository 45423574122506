<template>
  <div class="box-footer">
    <van-tabbar v-model="active" style="border-top: 1px solid #E5E5E5; z-index: 9999999999 !important;" active-color="0E99EA">
      <van-tabbar-item name="index" icon="shop-o" to="/">明厨亮灶</van-tabbar-item>
      <van-tabbar-item name="homeFood" icon="location-o" to="/Maps">地图</van-tabbar-item>
      <van-tabbar-item name="information" icon="newspaper-o" to="/information">
        资讯
      </van-tabbar-item>
      <van-tabbar-item name="personalInfo" icon="manager-o" to="/personalInfo">
        我的
      </van-tabbar-item>
    </van-tabbar>
    <!-- <van-tabbar v-model="active" style="border-top: 1px solid #E5E5E5;" active-color="0E99EA" inactive-color="#000">
      <van-tabbar-item name="index" to="/">
        <span>名厨亮灶</span>
        <template #icon="props">
          <img :src="props.active ? socials : social" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="homeFood" icon="location-o" to="/Maps">地图</van-tabbar-item>
      <van-tabbar-item name="information" to="/information">
        <span>资讯</span>
        <template #icon="props">
          <img :src="props.active ? informations : information" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="personalInfo" icon="manager-o" to="/personalInfo">
        我的
      </van-tabbar-item>
    </van-tabbar> -->
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted,watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import schoolImg from "@/assets/images/footer/school.png";
import schoolsImg from "@/assets/images/footer/schools.png";
import socialImg from "@/assets/images/footer/social.png";
import socialsImg from "@/assets/images/footer/socials.png";
import informationImg from "@/assets/images/footer/information.png";
import informationsImgs from "@/assets/images/footer/informations.png";
export default defineComponent({
  name: "foooter",
  setup() {
    let router = useRouter();
    let route = useRoute();
    const active = ref('index');
    // const foooterImgs = reactive({
    //   school: schoolImg,
    //   schools: schoolsImg,
    //   social: socialImg,
    //   socials: socialsImg,
    //   information: informationImg,
    //   informations: informationsImgs,
    // });
    // watch(route,()=>{
    //   console.log(route.name)
    //   active.value = route.name
    // })
    // onMounted(() => {
    //     active.value = route.name
    // });
    return {
      active,
      // ...toRefs(foooterImgs),
    };
  },
});
</script>

<style scoped lang="less">
.box-footer {
  width: 100%;
  // 
  height: auto;
  position: fixed;
  left: 0;
  bottom: 0;
}
</style>