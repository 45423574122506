<template>
  <div>
    <router-view v-wechat-title="Title"/>
    <footer class="hu_footer" v-show="this.$route.meta.show">
      <Footers />
  </footer>
  </div>
</template>
<script>
import { ref, defineComponent } from "vue";
import {getccconfig} from '@/api/index';
import Footers from "./views/layout/foooter.vue";
export default {
  mounted(){
    this.System_information();
  },
  setup(){
      let Title=ref('');
      const System_information=()=>{
          let query={
              info:'title'
          };
          getccconfig(query).then((res) => {
              let d=res.data.mydata;
              Title.value=eval('(' + d + ')').title;
              console.log(Title.value)
          }).catch((err) => {
              console.log(err)
          });
      }
      return{
          //ϵͳ��Ϣ
          System_information,
          Title
      }
  },
  components:{
      Footers,
  }
}
</script>
<style lang="less">
.hu_footer{
  width: 100%;
  height: 4rem;
  z-index: 9999999999 !important;
  position: fixed;
  left: 0;
  bottom: 0;
}
</style>
